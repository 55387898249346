(function () {
  angular.module('elmsSpaApp').component('capsloNavigationComponent', {
    template: require('ajs/custom_modules/capslo/app/navigation.html').default,
    controller: NavigationController,
    controllerAs: 'vm',
    bindings: {
      nonInteractive: '<?',
    },
  });

  /* @ngInject */
  function NavigationController(
    $window,
    rootScopeService,
    $state,
    currentUser,
    menuService,
    globalConfig,
    siteSettingService,
    securityService,
    drawerService,
  ) {
    var vm = this;

    vm.state = $state;
    vm.allTabs = []; //default blank tabs list

    vm.isStateAvailable = securityService.isStateAvailable;
    vm.appSettings = globalConfig.settings;
    vm.userSettings = currentUser.get();
    vm.siteSetting = siteSettingService.getSiteSetting();

    vm.login = login;
    vm.drawerService = drawerService;

    vm.$onInit = activate;
    vm.print = print;
    vm.isAdminView = globalConfig.settings.view === 'admin';

    function activate() {
      // initializes menu on start of the application
      initUserEnvironment();

      function initUserEnvironment() {
        vm.allTabs = menuService.getMenuItems();
      }
    }

    function login() {
      rootScopeService.broadcast('event:auth-loginRequired');
    }

    function print() {
      $window.print();
    }
  }
})();
