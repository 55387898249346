(function () {
  angular.module('kmi.lms.core').config(coreModuleConfig);

  function coreModuleConfig($stateProvider) {
    $stateProvider
      .state('main', {
        url: '/u',
        template: require('ajs/custom_modules/capslo/app/base-view.html').default,
        abstract: true,
      })
      .state('mainPrint', {
        url: '/p',
        template: require('ajs/custom_modules/capslo/app/base-view-print.html').default,
        abstract: true,
      })
      .state('prompt', {
        url: '/prompt',
        component: 'promptView',
        abstract: true,
      });
  }
})();
