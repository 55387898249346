(function () {
  angular.module('kmi.lms.user').config(function ($stateProvider) {
    $stateProvider.state('edit.account', {
      url: '/account?activeView',
      component: 'editUserAccount',
      params: {
        activeView: null,
      },
      data: {
        label: 'Account edit',
        availableFor: ['regularUser', 'admin'],
      },
      resolve: {
        user: function (currentUser, User, customUserAttributesService) {
          return User.get().then(function (user) {
            var updatedUser = customUserAttributesService.loadCustomAttributes(user);
            currentUser.set(updatedUser);
            return updatedUser;
          });
        },
      },
    });
  });
})();
