import {
  configureApplicationRuntimeSettings,
  configureApplicationSettings,
} from '../../modules/app/configuration/application-configuration';

require('ajs/vendors');
require('ajs/modules/app/models/class.ts');
require('ajs/global-dependencies.ts');
require('ajs/global-scope.ts');
require('ajs/modules/app/_init.ts');

declare const angular: angular.IAngularStatic;

angular
  .module('elmsSpaApp', [
    'ui.router',
    'ui.router.upgrade',
    'http-auth-interceptor',
    'ui.bootstrap',
    'angular-ladda',
    'ngCookies',
    'ngAnimate',
    'ngResource',
    'ui.mask',
    'ngSanitize',
    'ngAria',
    'globalDependencies',
    'kmi.lms.services',
    'blueimp.fileupload',
    'kmi.lms.core',
    'kmi.lms.core.login',
    'kmi.lms.components',
    'kmi.lms.home',
    'kmi.lms.user.certificates',
    'kmi.lms.search',
    'kmi.lms.user',
    'kmi.lms.quiz',
    'kmi.lms.course.quizzes',
    'kmi.lms.course.common',
    'kmi.lms.course.details',
    'kmi.lms.course.registrations',
    'kmi.lms.user.learning',
    'kmi.lms.course.custom-list',
    'kmi.lms.passwords',
  ])
  .config(configureApplicationSettings)
  .config(moduleConfig)
  .run(configureApplicationRuntimeSettings);

require('ajs/core-modules');

require('ajs/custom_modules/capslo/_init.ts');
require('ajs/custom_modules/capslo/app/navigation-component.ts');
require('ajs/custom_modules/capslo/user/user-config.ts');
require('ajs/custom_modules/capslo/user/learning/_init.ts');
require('ajs/custom_modules/capslo/search/_init.ts');

require('src/projects/capslo/index.module.ts');

function moduleConfig($stateProvider) {
  $stateProvider.state('main.home', {
    url: '^/home?state',
    component: 'generalHome',
    params: {
      state: null,
    },
    data: {
      label: 'Home',
      availableFor: ['regularUser', 'admin'],
    },
  });
}
